.landing__background-image {
    z-index: -2;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.landing__video  {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.landing__video video  {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.landing__background-color {
    background: rgba(0, 0, 0, 0.6);
}

.landing__content {
    display: flex;
    flex-direction: column;
    justify-content:space-around;

    height: 100vh;
    width: 80%;
    margin-left: 10%;
}

.landing__content h1 {
    color: var(--panton-light);

    text-align: center;
    /* H4 */
    font-family: Inter;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 1rem 0;
}

.landing__content h2 {
    color: var(--panton-light);

    text-align: center;
    /* H4 */
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}

.landing__content p {
    color: var(--panton-milk);

    text-align: center;
    /* PF3 */
    font-family: Inter;
    font-size: 1.70rem;
    font-style: normal;
    font-weight: 450;
    line-height: auto;

    margin: 0;
    padding: 0;
}

.landing__show-more {
    width: 100%;
    margin-top: auto;
    align-self: center;
    padding-bottom: 0.25rem;
}

.landing__show-more a {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: none;
    text-decoration: none;
    margin: 0;

    transition: 250ms all;
}

.landing__show-more a:hover,
.landing__show-more a:active {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px)
}

.landing__show-more p {
    padding: 0.5rem 2rem;
    color: var(--panton-white);
   

    /* PF4 */
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 175% */
}

@media screen and (min-width: 2000px) {
    .landing__content {
        width: 60%;
        margin-left: 20%;
    }
}

@media screen and (max-width: 850px) {

    .landing__video video  {
        
    }
    

    .landing__content h1 {
        font-size: 2.20rem;
    }

    .landing__content h2 {
        font-size: 1.70rem;
    }

    .landing__content p {
        font-size: 1.45rem;
    }

    .landing__content a {
        font-size: 1.70rem;
    }
}

@media screen and (max-width: 650px) {
    .landing__content {
        width: 90%;
        margin-left: 5%;
    }

    .landing__content h1 {
        font-size: 1.60rem;
    }

    .landing__content h2 {
        font-size: 1.4rem;
    }

    .landing__content p {
        font-size: 1.10rem;
    }

    .landing__content a {
        margin: 1rem 0.5rem;
        font-size: 1.40rem;
    }

    .landing__row {
        margin: 0;
    }

    .landing__show-more p {
    
        font-size: 1.0rem;
        line-height: 1.0rem; /* 175% */
    }

    .landing__show-more a {
    
        margin: 0;
    }
}

@media screen and (max-height: 800px) {
    .landing__content {
        height: 800px;
    }
}



/* ----------------------------------------------
 * Generated by Animista on 2024-6-11 14:4:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}

.bg-pan-br{-webkit-animation:bg-pan-br 30s both;animation:bg-pan-br 30s both}
.text-focus-in{-webkit-animation:text-focus-in 1s cubic-bezier(.55,.085,.68,.53) both;animation:text-focus-in 1s cubic-bezier(.55,.085,.68,.53) both}
.text-blur-out{-webkit-animation:text-blur-out 1.2s cubic-bezier(.55,.085,.68,.53) both;animation:text-blur-out 1.2s cubic-bezier(.55,.085,.68,.53) both}

@-webkit-keyframes bg-pan-br{0%{background-position:0 0}100%{background-position:100% 100%}}@keyframes bg-pan-br{0%{background-position:0 0}100%{background-position:100% 100%}}
@-webkit-keyframes text-focus-in{0%{-webkit-filter:blur(12px);filter:blur(12px);opacity:0}100%{-webkit-filter:blur(0);filter:blur(0);opacity:1}}@keyframes text-focus-in{0%{-webkit-filter:blur(12px);filter:blur(12px);opacity:0}100%{-webkit-filter:blur(0);filter:blur(0);opacity:1}}
@-webkit-keyframes text-blur-out{0%{-webkit-filter:blur(.01);filter:blur(.01)}100%{-webkit-filter:blur(12px) opacity(0);filter:blur(12px) opacity(0)}}@keyframes text-blur-out{0%{-webkit-filter:blur(.01);filter:blur(.01)}100%{-webkit-filter:blur(12px) opacity(0);filter:blur(12px) opacity(0)}}

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */

 @-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
@keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
