/* Functionality regarding the language menu in the top bar

   The bar should be foldable (in and out) but should also turn transparent when the top bar turns transparent. 
   It should also dissapear when the user fold out the side bar.
*/

.header__content-language-button {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-self: flex-end;

    margin-left: 0;
    padding-right: 0.5rem;
    padding-left: 1rem;
    cursor: pointer;
}


.header__content-language-button p {
    color: var(--panton-white);

    /* K1 */
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.header__content-language-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.header__content-language-button:active {
    background: rgba(255, 255, 255, 0.30);
}


.header__language-bar {
    display:flex;
    z-index: 100;
    max-width: 12rem;
    background: rgba(0, 0, 0, 0.5);
    flex-direction: column;
    margin-left: auto;
    margin-right: 6%;
    padding: 0.15rem 0.75rem 0.25rem 0.75rem;
}

.header__language-bar-transparent {
    background: transparent;
}

.header__language-bar-solid {
    background: rgba(0, 0, 0, 0.666);
}

.header__language-bar-off {
    display:none; 
}

.header__language-bar span {
    color: var(--panton-white);

    /* K1 */
    width: 100%;
    font-family: Inter;
    font-size: 1.15rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    margin: 0.35rem 0;
    padding: 0.3rem 0;
    cursor: pointer;

    transition: 250ms all;
}

.header__language-bar span:hover,
.header__language-bar span:active {
    background: rgba(255, 255, 255, 0.2);
}
@media screen and (min-width: 2000px) {
    .header__language-bar {
        margin-right: 17%;
    }
}

@media screen and (max-width: 1050px) {
    .header__language-bar {
        margin-right: 0%;
    }


    .header__content-language-button p {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 850px) {
    .header__content-language-button {
        padding-left: 0.5rem;
    }
    
    .header__content-lang p {
        font-size: 1rem;
    }

    .header__language-bar {
        
    }

    .header__language-bar span {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 650px) {
    .header__content-language-button {
        margin-left: auto;
    }

    .header__content-language-button p {
        font-size: 1rem;
    }

    .header__language-bar {
        
    }

    .header__language-bar span {
        font-size: 1.0rem;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-11 7:59:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in and out
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.666);
    }
}
@keyframes fade-in {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.666);
    }
}

@keyframes fade-out{0%{background: rgba(0, 0, 0, 0.666);}100%{background: rgba(0, 0, 0, 0)}}
  
/**
 * ----------------------------------------
 * animation swing in and out
 * ----------------------------------------
 */

.swing-in-top-fwd{-webkit-animation:swing-in-top-fwd .5s cubic-bezier(.175,.885,.32,1.275) both;animation:swing-in-top-fwd .5s cubic-bezier(.175,.885,.32,1.275) both}

.swing-out-top-bck{-webkit-animation:swing-out-top-bck .45s cubic-bezier(.4,-.28,.535,.045) both;animation:swing-out-top-bck .3s cubic-bezier(.6,-.28,.735,.045) both}

@-webkit-keyframes swing-in-top-fwd{0%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}100%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}}@keyframes swing-in-top-fwd{0%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}100%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}}

@-webkit-keyframes swing-out-top-bck{0%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}100%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}}@keyframes swing-out-top-bck{0%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}100%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}}