
.home__offer {
        
}

.home__offer h1 {
    text-align: center;
    margin-top: 0rem;
    margin-bottom: 1.25rem;
}

.home__offer-container {
    margin-top: 0.75rem;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap for better responsiveness */
    gap: 2.5rem; /* Add some spacing between items */
}

.home__offer-container-item-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    flex: 1 0 40%;
    height: 450px; /* Fixed height to maintain aspect ratio */
    border-radius: 2px;
    box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.25);
}

.home__offer-container-item {
    background-color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: flex-start; /* Center content horizontally */
    justify-content: flex-start; /* Center content vertically */
    overflow: hidden;
}

.home__offer-container-item:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

.home__offer-container-item h1 {
    padding: 1.5rem 0;
    align-self: center;
    color: var(--panton-white);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    padding-left: 0.75rem;
    
    /* PF3 */
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem; /* 140% */
}

.home__offer-container-item p {
    padding: 1.5rem;
    color: var(--panton-white);
    margin-top: auto;

    /* PF4 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 175% */
}

.home__offer-container-item-arrow {
        position: absolute;
        top: calc(50% - 24px);
        right: 0.5rem;
}

@media screen and (max-width: 1050px) {
    .home__offer-container-item-image {
        height: 325px; /* Fixed height to maintain aspect ratio */
    }
}

@media screen and (max-width: 650px) {
    .home__offer h1 {
        margin-top: 1.5rem;
    }

    .home__offer-container-item-image {
        flex: 1 0 50%;
    }

    .home__offer-container {
        padding: 1rem;
    }

    .home__offer-container-item p {
        font-size: 1.0rem;
        font-weight: 350;
        line-height: 1.4rem; /* 175% */
    }
}

.hidden {
    opacity: 0;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-13 11:29:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.slide-in-blurred-bottom {
	-webkit-animation: swing-in-bottom-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-bottom-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-25 10:23:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-bottom-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-bottom-fwd {
        0% {
                -webkit-transform: rotateX(100deg);
                        transform: rotateX(100deg);
                -webkit-transform-origin: bottom;
                        transform-origin: bottom;
                opacity: 0;
        }
        100% {
                -webkit-transform: rotateX(0);
                        transform: rotateX(0);
                -webkit-transform-origin: bottom;
                        transform-origin: bottom;
                opacity: 1;
        }
        }
        @keyframes swing-in-bottom-fwd {
        0% {
                -webkit-transform: rotateX(100deg);
                        transform: rotateX(100deg);
                -webkit-transform-origin: bottom;
                        transform-origin: bottom;
                opacity: 0;
        }
        100% {
                -webkit-transform: rotateX(0);
                        transform: rotateX(0);
                -webkit-transform-origin: bottom;
                        transform-origin: bottom;
                opacity: 1;
        }
}
      

/* ----------------------------------------------
 * Generated by Animista on 2024-6-14 15:12:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */

.heartbeat {
	-webkit-animation: heartbeat 2.5s ease-in-out infinite both;
	        animation: heartbeat 2.5s ease-in-out infinite both;
}

 @-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
@keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
  