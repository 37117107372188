.sidebar__contents {
    display: none;
    height: 100vh;
    flex-direction: column;
    opacity: 0.98;
    width: 100%;
    
    position: relative;
    background: linear-gradient(188deg, rgba(51, 0, 114, 0.96) 0%, rgba(124, 1, 131, 0.92) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    padding: 0.75rem 0.5rem;

    border-radius: 0;
}

.sidebar__contents-off {
    display: none;
    position: fixed;
}

.sidebar__contents a {
    padding: 0.15rem 0.4rem;
    
    color: var(--panton-white);

    /* H4 */
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
}

.sidebar__contents a:hover {
    background: rgba(255, 255, 255, 0.10);
}

.sidebar__contents-submenu {
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
}

.sidebar__contents-submenu a {
    color: var(--panton-white);   

    /* H4 */
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2rem;
    text-decoration: none;
    margin-top: 0.25rem;
}

.sidebar__contents hr {
    opacity: 0.33;
    margin: 0.5rem 0.5rem;
}

@media screen and (max-width: 650px) {
    .sidebar__contents {
        display: flex;
    }
}

 
 /* ----------------------------------------------
 * Generated by Animista on 2024-6-14 12:52:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */

 .scale-in-hor-right {
	-webkit-animation: scale-in-hor-right 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-right 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-out-hor-right {
	-webkit-animation: scale-out-hor-right 0.35s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-hor-right 0.35s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
 /**
 * ----------------------------------------
 * animation scale-in-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-right {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-hor-right {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  
  
  
/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * animation scale-out-hor-right
 * ----------------------------------------
 */
 @-webkit-keyframes scale-out-hor-right {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-out-hor-right {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  