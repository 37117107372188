.login__body-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.login__body-overlay {
    background: rgba(0, 0, 0, 0.666);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;

}

.login__body-overlay h1 {
    color: var(--Ljuspanton, #E1CAFE);

    text-align: center;
    /* H4 */
    font-family: Inter;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 2rem 0;
}

.login__body-overlay p {
    color: yellow !important;

    text-align: center;
    /* H4 */
    font-family: Inter;
    font-size: 1.5rem !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    margin-bottom: 1rem;
}

.login__body-overlay p {
    color: var(--Ljuspanton, #E1CAFE);

    text-align: center;
    /* H4 */
    font-family: Inter;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.login__body-overlay {
    [title="Användarnamn"] {
        color: white !important;
      }
      
    [title="Lösenord"] {
    color: white !important;
    }
}

.login__buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}