.contact__map {
    max-width: 1080px;
    margin: auto;
    display: flex;
    gap: 1rem;
}

.contact__map p {
    font-size: 1.3rem;
}

.contact__map iframe {
    width: 100%;
    height: 22rem;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.contact__map h2 {
    color: var(--panton-purple-text);
}

.contact__map-frame {
    flex: 1;
    margin: 0 0.25rem;
}

@media screen and (max-width: 800px) {
    .contact__map {
        flex-direction: column-reverse;
        gap: 0.5rem;
    }

    .contact__map-text {
        align-self: center;
    }
}