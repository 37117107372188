h1 {
    color: var(--panton-black);

    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.app__dark-purple label {
    color: black !important;
}

*,
html {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 650px) {
  h1 {
    font-size: 1.75rem;
    font-weight: 550;
}

  .app__no-mobile {
    display: none;
  }
}

.half-width {
  width: 60%;
  margin: auto;
}

.h1 {
    font-family: Inter;
    font-size: 2.5rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 1rem 0;
}

.h2 {
    font-family: Inter;
    font-size: 2rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}

.h3 {
    font-family: Inter;
    font-size: 1.7rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: 2.0rem;
}

.h4 {
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.p1 {
    font-family: Inter;
    font-size: 1.25rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5em; /* 175% */
}

.p2 {
    font-family: Inter;
    font-size: 1.0rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
}

.center {
    text-align: center;
}

.b1 {
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    transition: all 250ms;
    cursor: pointer;
}

.b2 {
    font-family: Inter;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    transition: all 250ms;
    cursor: pointer;
}

.b3 {
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    transition: all 250ms;
    cursor: pointer;
}

.b4 {
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    transition: all 250ms;
    cursor: pointer;
}

.glowing {
    text-shadow:1px 1px 5px #fff, 1px 1px 15px #ccc;
}

@media screen and (max-width: 850px) {
    .half-width {
        width: 75%;
    }

    .h1 {
        font-size: 2.35rem !important;
        line-height: normal;
    
        margin: 1rem 0;
    }
    
    .h2 {
        font-size: 1.85rem !important;
        line-height: 1.5rem;
    }
    
    .h3 {
        font-size: 1.5rem !important;
        line-height: 2.0rem;
    }
    
    .h4 {
        font-size: 1.35rem;
        line-height: 1.5rem;
    }

    .p1 {
        font-size: 1.2rem !important;
        line-height: 1.5em; /* 175% */
    }
    
    .p2 {
        font-size: 0.95rem !important;
        line-height: 1.4;
    }

    .b1 {
        font-size: 1.85rem;
    }
    
    .b2 {
        font-size: 1.6rem;
    }
    
    .b3 {
        font-size: 1.35rem;
    }
    
    .b4 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 650px) {
    .half-width {
        width: 100%;
        margin: 0;
    }

    .h1 {
        font-size: 2.25rem !important;
        line-height: normal;
    
        margin: 1rem 0;
    }
    
    .h2 {
        font-size: 1.75rem !important;
        line-height: 1.5rem;
    }
    
    .h3 {
        font-size: 1.4rem !important;
        line-height: 2.0rem;
    }
    
    .h4 {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .p1 {
        font-size: 1.15rem !important;
        line-height: 1.5em; /* 175% */
    }
    
    .p2 {
        font-size: 0.9rem !important;
        line-height: 1.4;
    }

    .b1 {
        font-size: 1.75rem;
    }
    
    .b2 {
        font-size: 1.5rem;
    }
    
    .b3 {
        font-size: 1.25rem;
    }
    
    .b4 {
        font-size: 1.0rem;
    }
}