

.contact__landing-main {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
}

.contact__landing h1 {
    font-size: 2.25rem;
}

.contact__landing-jour {
    align-self: center;
}

.contact__landing-address {
    
}

.contact__landing-icon-text {
    display: flex;
    gap: 0.75rem; 
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.contact__landing-icon-text p {
    margin: 0 0.75rem;
}

.contact__landing-component {
    margin-bottom: 4rem;
}

.contact__landing-address-text {
    display: flex;
    flex-direction: column;
}

.contact__landing-address-text p {
    text-align: left;
    margin: 0.4rem 0.75rem;
}

.contact__landing-links {
    padding-left: 2rem;
}

.contact__landing-links h2 {
    margin-top: 1.5rem;
    font-size: 1.6rem;
    line-height: 0.2rem;
}

.contact__landing-bottom-link {
    display: flex;
    flex-direction: column;
}

.contact__landing-placeholder {
    margin:2rem;
}

.contact__landing-icon {
    align-self: flex-start;
    margin-top: 0.20rem;
}


.contact__content {
    width: 80%;
    margin: 0 10%;
    margin-bottom: 4rem;
}

.contact__content h1 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.contact__component {
    margin-top: 2rem;
}

.contact__component h1 {
    margin-bottom: 1rem;
}

.contact__form-image {
    height: 32rem;
}

.contact__form-overlay {
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(153deg, rgba(53, 0, 69, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
    height: 100%;

   
}

.contact__contact-info {
    margin: 1rem auto;
    max-width: 600px;
    padding: 0.75rem;
    text-align: center;
}

@media screen and (max-width: 1050px) {
    .contact__landing h1 {
        font-size: 2rem;
    }

    .contact__landing p {
        font-size: 1.5rem;
    }

    .contact__landing-main {
        gap: 1rem;
    }
}

@media screen and (max-width: 850px) {
    .contact__landing-address {
        display: none;
    }

    .contact__landing-component {
        margin-bottom: 2rem;
    }

    .contact__landing-links {
        flex-direction: column;
        align-items: center;
        margin: auto;
        width: auto;
        padding-left: 0;
    }

    .contact__landing-links h2 {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .contact__landing-links a {
        font-size: 1.5rem;
    }

    .contact__landing-main {
        flex-direction: column;
        gap: 0;
    }
}

@media screen and (max-width: 650px) {
    .contact__component {
        padding: 0.25rem;
    }
    
    .contact__landing h1 {
        font-size: 1.7rem;
    }
    
    .contact__landing p {
        font-size: 1.3rem;
    }
    
    .contact__form-image {
        height: auto;
    }
    
    .contact__landing-icon-text {
        margin: 1rem 0;
    }

    .contact__landing {
        margin-left: -0.75rem;
    }

    .contact__content {
        
        width: 100%;
        margin: 0 0;
        margin-bottom: 3rem;
    }

    .contact__landing-address {
        display: none;
    }

    .contact__landing-links {
        flex-direction: column;
        justify-content:flex-start;
    }

    .contact__component {
        
    }
}