.news__item {
    display: flex;
    flex-direction: column;
    background: var(--panton-white);
    align-items: flex-start;
    border-radius: 0.125rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    height: 92%;
    width: 92%;
    max-width: 32rem;
    margin: auto;

    overflow-y: auto;
}

.news__item img {
    height: 60%;
    width: 100%;
    flex:1;
    object-fit:cover;
}

.news__item h1 {

    color: var(--panton-black);

    margin: 0;
    padding: 0;

    /* H5 */
    font-family: Inter;
    text-align: left !important;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 0 0.5rem;
}

.news__item p {
    margin: 0;
    padding: 0 0.5rem;
}

.news__item hr {
    background: var(--panton-light);
    margin: 0.75rem 0.5rem;
    height: 0.1rem;
    border: none;
}


.news__item-morenews {
    padding: 0.1rem;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    display: flex;
    align-self: flex-end;
    transition: all 250ms;

    cursor: pointer;
}

.news__item-morenews:hover,
.news__item-morenews:active {
    transform: translateY(-3px);
}

.news__item-morenews span {
    color: var(--panton-blue);
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    text-decoration: none;
    padding: 0.1rem;

    /* K1 */
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-top: 1px;
}

#news__item-date {
    color: var(--panton-black);

    /* T1 */
    font-family: Inter;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.1rem; /* 140% */
    margin-top: 0.1rem;
}

#news__item-text-short {
    display: none;
    color: var(--panton-black);

    /* P3 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 1.25rem; /* 140% */
}

#news__item-text-long {
    display: flex;
    color: var(--panton-black);

    /* P3 */
    font-family: Inter;
    font-size: 1.0rem;
    font-style: normal;
    font-weight: 350;
    line-height: 1.5rem; /* 140% */
}

.news__item-full-text {
    font-size: 1.0rem;
    margin-bottom: 1rem !important;
}

.news__opened-news {
    .news__item {
        border: none;
        box-shadow: none;
        max-height: 75vh;
        max-width: 100%;
    }
}

.news__desktop {
    display: inline;
}

.news__mobile {
    display: none;
}

@media screen and (max-width: 650px) {
    .news__desktop {
        display: none;
    }

    .news__mobile {
        display: inline;
    }

    .news__item h1 {
        font-size: 1.3rem;
        line-height: normal;
    }

    #news__item-date {
        font-size: 1.05rem;
        line-height: 1.15rem; /* 140% */
    }

    #news__item-text-short {
        display: flex;
    }
    
    #news__item-text-long {
        display: none;
    }

    .news__item {    
        height: 90%;
    }

    .news__item img {
        max-height: 55%;
    }

}
