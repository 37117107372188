.body__content {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-top: 3rem;
}

.body__content h1 {
    text-align: center;
}

.body__content-empty-space {
    height: 4rem;
}

@media screen and (min-width: 2000px) {
    .body__content {
        width: 60%;
        margin-left: 20%;
    }
}

@media screen and (max-width: 850px) {
    .body__content {
        width: 96%;
        margin-left: 2%;
    }
}

@media screen and (max-width: 650px) {
    .body__content {
        width: 100%;
        margin-left: 0%;
        margin-top: 0;
    }

    .body__content-empty-space {
        height: 2rem;
    }
}