.contact__content-people {
    background: linear-gradient(153deg, rgba(254, 251, 255, 1) 0%, rgba(248, 238, 255, 1) 100%);
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    gap: 2rem;
    padding: 1.5rem 0;
}

.contact__people {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 320px;
    
    padding: 1rem;
    border-radius: 0.15rem;
    background: linear-gradient(162deg, rgba(75, 5, 161, 0.88) 4.03%, rgba(58, 1, 128, 0.75)  80.74%);
    box-shadow: 2px 5px 5px 1px rgba(0, 0, 0, 0.25);
}

.contact__people h1 {
    color: var(--panton-milk);

    text-align: center;
    /* H4 */
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.contact__people-roles {
    margin-bottom: 0.5rem;
}

.contact__people-prime-role {
    font-size: 1.1rem;
    color: var(--panton-purple-text);
}

.contact__people-second-role {
    font-size: 0.9rem;
    color: var(--panton-milk);
}

.contact__people h2 {
    text-align: center;
    /* H5 */
    font-family: Inter;
    
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

}

.contact__people p {
    color: var(--panton-white);

    /* P2 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact__people-icon-field {
    display: flex;
    margin: 0.25rem 0;
}

.contact__people-icon-field p {
    margin-left: 0.5rem;
}

@media screen and (max-width: 750px) {
    .contact__people {
        max-width: 280px;
    }

    .contact__people h1 {
        font-size: 1.2rem;
    }

    .contact__people-prime-role {
        font-size: 1.0rem;
    }
    
    .contact__people-second-role {
        font-size: 0.85rem;
    }

    .contact__people p {
        font-size: 0.9rem;
    }
}