
.offer__landing {
    margin-left: 4rem;
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

.offer__landing p {
    text-align: left;
    max-width: 44rem;
}

.offer__landing-slogan p {
    margin-bottom: 0;
    line-height: 0.75rem;
}

.offer__landing-slogan h1 {
    color: var(--panton-milk);
    text-align: left;
    font-size: 4.5rem;
    margin-top: 0;
}

.offer__landing-buttons {
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
}

.offer__landing-buttons a {
    padding: 0.5rem 1.5rem;
}

.contact__offer-form h1 {
    margin-bottom: 1rem;
}

.offer__content-form_background {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    
    position: absolute;
    background: var(--panton-light);
    width: 80%;
    height: 20rem;
    margin-top: 4rem;

    z-index: -1;
}

@media screen and (min-width: 2000px) {
    .offer__content-form_background {
        width: 60%;
    }
}


@media screen and (max-width: 1050px) {    
    .offer__landing {
        margin-left: 0;
    }
}

@media screen and (max-width: 850px) {
    .offer__landing-slogan h1 {
        font-size: 4.0rem;
    }

    .offer__landing-buttons {
        gap: 1rem;
    }

    .offer__content-form_background {
        width: 96%;
    }
}

@media screen and (max-width: 650px) {
    .offer__landing h1 {
        font-size: 3rem;
        padding: 0;
    }

    .offer__landing-buttons {
        flex-direction: column;
        gap: 0;
    }

    .offer__landing-buttons a {
        max-width: 24rem;
    }

    .offer__contact-content {
        padding: 0 1rem;
    }

    .offer__content-form_background {
        display: none;
    }
}
 