.form__container {
    display:flex;
    flex-direction: column;
    
    align-items: center;    

    margin: 0 auto;
    padding: 0.5rem 0.5rem;
    max-width: 40rem;

    &.form__dark-color {
        /*background: linear-gradient(162deg, rgba(140, 47, 255, 0.75) 4.03%, rgba(82, 0, 184, 0.666) 80.74%);*/
        border-radius: 0.25rem;
        background: linear-gradient(162deg, var(--panton-lighter) 4.03%, var(--panton-milk) 80.74%);
        box-shadow: 0px 6px 10px 2px rgba(28, 0, 128, 0.25);
    }
}



.form__container form {
    display: flex;
    flex-direction: row;
}

.form__container-row {

    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 19rem;
    margin: 0 0.5rem;
}

.form__container h1 {
    color: #FAFAFA;

    text-align: center;
    /* H3 */
    font-family: Inter;
    font-size: 2.0rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
}

.form__container label {
    color: var(--panton-purple-text);
    margin-left: 0.25rem;

    /* PF4 */
    font-family: Inter;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 175% */
}

.form__error-message {
    height: 0.75rem;
    color: coral !important;
    margin-left: 0.25rem !important;

    /* PF4 */
    font-family: Inter;
    text-align: left !important;
    font-size: 0.8rem !important;
    font-style: normal;
    font-weight: 300;
    line-height: 1.25rem !important; /* 175% */
}

.form__dark-color .form__error-message  {
    color: rgb(209, 0, 0) !important;
}

.form__select {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
    margin-bottom: 1rem;
    
}

.form__field {
    flex: 1;
    margin: 0.25rem 0;
}

.form__field-text {
    margin-bottom: 0.5rem;
}

.form__field-file {
    flex: 1;
    display: flex;
    flex-direction: column;

    margin: 0.5rem 0;

    color: var(--panton-white);

    /* PF4 */
    font-family: Inter;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 175% */
}

.form__field-file input {
    color: var(--panton-white);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    padding: 0.15rem;

    /* PF4 */
    font-family: Inter;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 175% */
}

.form__field-file span {

    color: rgba(255, 255, 255, 0.666);
    font-weight: 400;
}


.form__radio {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    margin-top: -0.2rem;
}


.form__dark-color label {
    color: var(--panton-medium);
}

.form__dark-color span {
    color: var(--panton-medium);
}

.form__radio label {
    padding-top: 0.4rem;
    margin-top: 0;
}

.form__radio span {
    color: black;
}

input[type=file]::file-selector-button {
    margin-right: 20px;
    background-color: #FFFFFF;
    padding: 6px 20px;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    transition: all 250ms;
  }
  
input[type=file]::file-selector-button:hover {
    transform: translateY(-1px);
}

input[type=file]::file-selector-button:hover,
input[type=file]::file-selector-button:focus {
  color: rgba(0, 0, 0, 0.65);
}

.form__secure-button {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
}

.form__secure-button p {
    font-family: Inter;
    font-size: 11pt;
    font-weight: 350;
    line-height: 14pt;
}

/* imported style for button */

/* CSS */
.button-6 {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

    background-color: var(--panton-white);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.0;
    padding: calc(.5rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    margin-bottom: 1rem;
  }

  .contact__offer-form .button-6 {
    margin-top: 2.1rem;
  }
  
  .button-6:hover,
  .button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
  }
  
  .button-6:hover {
    transform: translateY(-1px);
  }
  
  .button-6:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }

@media screen and (max-width: 850px) {
    .form__container-row {
        min-width: 14rem;
    }

}

@media screen and (max-width: 650px) {
    .form__error-message {
        height: 0.9rem;    
    }

    .form__container {
        padding: 0 0.25rem;
        margin: 0 0.5rem;
    }

    .form__dark-color .button-6 {
        margin-top: 1rem;
      }
    
    .form__container-row {
        min-width: 0;
    }
    .form__container form {
        flex-direction: column;
        width: 100%;
    }

    .form__field {
        margin: 0;
        margin-top: 0rem;
    }

    .form__select {
        margin-top: 0;
        margin-bottom: 0.75rem;
    }

    .form__field-file {
        margin: 0.25rem 0;
    }
    
    .form__container h1 {
        padding-top: 1.5rem;
    }

    .button-6 {
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
    }
}
