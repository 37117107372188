.home__about-overlay {
    background: linear-gradient(145deg, rgba(10, 0, 22, 0.65) 10%, rgba(13, 0, 29, 0.85) 90%);
}

.home__about-content {
    width: 60%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 32rem;
    
    padding: 1rem 1.5rem;
}

.home__about-content h1 {
    color: var(--panton-purple-text);
}

.home__about-content-text {
    
}

.home__about-content-text h1 {
    font-size: 1.7rem;
    margin-bottom: 0.75rem;
}

.home__about-content p {
    color: var(--panton-white);
    margin-top: auto;

    /* PF4 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75em; /* 175% */
}

.home__about-content a {
    display: flex;

    align-items: center;
    align-self: center;
    transition: all 250ms;
    border: 1px solid var(--panton-purple-text);
    border-radius: 0.25rem;
    padding: 0.25rem;

    color: var(--panton-purple-text);
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);

    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    /* PF4 */
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem; /* 175% */

    text-decoration: none;
}

.home__about-content a:hover {
    background-color: rgba(255, 255, 255, 0.20);
    transform: translateY(-2px);
}

.home__about-content a:active {
    background-color: rgba(255, 255, 255, 0.20);
    transform: translateY(-2px);
}

.home__about-content span {
    padding-left: 0.5rem;
}


#home__about-content-big {
    display: inline;
    }

#home__about-content-small {
    display: none;
}

@media screen and (max-width: 950px) {
    .home__about-content {
        width: 80%;
        margin-left: 10%;
    }
}

@media screen and (max-width: 650px) {

    .home__about-content {
        width: 100%;
        margin-left: 0;
        height: 16rem;
        padding: 1rem 0;
    }

    .home__about-content-text {
        padding: 1.5rem;
    }

    .home__about-content-text h1 {
        font-size: 1.15rem !important;
        text-align: start !important;
    }


    #home__about-content-big {
        display: none;
        }
    
    #home__about-content-small {
        display: inline;
    }

    .home__about-content a {
        font-size: 1.15rem;
    }

    .home__about-content-more {
        padding:0.25rem;
        margin-right: 0;
    }
}
