.logos__content {
    margin: 0;
    padding: 0;
}

.logos__content hr {
    background: var(--panton-medium);
    width: 98%;
    height: 1px;
    border: 1px solid var(--panton-palette);
    border-radius: 2px;
    opacity: 0.25;
    margin: 1.5rem 0 1rem 1%;
}

.logos__content h1 {
    text-align: left;
}

.logos__content h2 {
    color: #000;
    text-align: center;

    /* H4 */
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.logos__content-logos-area {
    background: var(--panton-white);
    box-shadow: 1px 5px 5px 3px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Wraps logos to the next line */
    justify-content: center; /* Aligns logos to the start */
    margin-bottom: 1rem;
}

.logos__content-logos {
    display: flex;
    flex-direction: column;

}

.logos__content-logos-container {
    width:25%;
}

.logos__content-logos-container img {
    width: 70%;
    margin-left: 15%;
}

@media screen and (max-width: 850px) {
    .logos__content-logos-container {
        width:33%;
    }
}

@media screen and (max-width: 650px) {
    .logos__content {
        padding: 1rem;
    }

    .logos__content-logos-container {
        width:50%;
    }
}