.consent {
    z-index: 11;

    position: sticky;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
    border-top: 2px solid var(--panton-white);
    background: var(--panton-purple);
    width: 100%;
    bottom: 0;
    padding: 1rem;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.consent__closed {
    display: none;
}

.consent h1 {
    margin-bottom: 0;
    line-height: 100%;
    text-align: center;
    color: var(--panton-white); 
}

.consent p {
    color: var(--panton-white); 
    text-align: center;
    line-height: 150%;
}

.consent__buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.consent_buttons-button {
    background: var(--panton-white);
    border-radius: 4px;
    padding: 0.25rem 1rem;
    color: var(--panton-black);
    cursor: pointer;
    transition: 250ms all;
    border: none;
}

.consent_buttons-button:hover,
.consent_buttons-button:active {
    background: var(--panton-gray-light);
    transform: translateY(-2px);
}


.consent_buttons-button2 {
    border: 1px solid var(--panton-white);
    border-radius: 4px;
    padding: 0.25rem 1rem;;
    color: var(--panton-white);
    cursor: pointer;
    transition: 250ms all;
}

.consent_buttons-button2:hover,
.consent_buttons-button2:active {
    background: rgba(255, 255, 255, 0.25);
    transform: translateY(-2px);
}

@media screen and (max-width: 650px) {

}