.footer__background {
    margin-top: 4rem;
}

.footer__overlay {
    background: linear-gradient(114deg, rgba(63, 1, 139, 0.666) 14.24%, rgba(28, 0, 63, 0.75) 58.6%);
}

.footer__content {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    flex-direction: row;
    padding: 1.5rem 0.5rem;

   
}

.footer__content h1 {
    color: var(--panton-purple-text);
    padding: 0.5rem;
    margin-bottom: 1rem;

    /* H5 */
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: 1.25rem; /* 140% */
}

.footer__content p {
    color: var(--panton-white);
    padding: 0.5rem;

    /* P3 */
    font-family: Inter;
    font-size: 1.05rem;
    font-style: normal;
  
    font-weight: 400;
    line-height: 0.8rem; /* 140% */
}

.footer__content hr {
    display: none;
}

.footer__content-logo {
    display: flex;
    flex-direction: column;
}

.footer__content-logo img {
    width: 10rem;
    justify-self: center;
    align-self: center;
}

.footer__content-support {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
}

.footer__content-support a {
    color: var(--panton-white);
    margin: 0.5rem 1.5rem;

    /* H5 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: normal;
    transition: all 250ms;
}

.footer__content-support a:hover {
    color: var(--panton-light)
}

.footer__content-socialmedia {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
}

.footer__content-socialmedia a {
    transition: all 250ms;
}

.footer__content-socialmedia a:hover {
    transform: translateY(-3px);
}

.footer__content-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 0;
}

.footer__content-pdf span {
    color: var(--panton-white);

    /* P3 */
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1rem; /* 140% */
    text-align: center;
    text-decoration: underline;
    transition: all 250ms;
}

.footer__content-pdf span:hover,
.footer__content-pdf span:active {
    color: var(--panton-light)
}

.footer__content-copyright p {
    color: var(--panton-white);
    padding: 1rem 0;

    /* P3 */
    font-family: Inter;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem; /* 140% */
    text-align: center;
    margin-left: 1rem;
}

@media screen and (max-width: 1050px) {
    .footer__content h1 {
        font-size: 1rem;
        line-height: 1.3rem; /* 140% */

    }

    .footer__content p {
        font-size: 0.9rem;
        line-height: 1.1rem; /* 140% */
    }

    .footer__content a {
        font-size: 0.9rem;
        line-height: 1.1rem; /* 140% */
    }

    .footer__content-socialmedia {
        gap: 0rem;
    }
}

@media screen and (max-width: 850px) {
    .footer__background {
        margin-top: 1rem;
    }

    .footer__content {
        flex-direction: column;
        justify-content: center;
    }

    .footer__content-socialmedia {
        align-self: center;
        justify-content: center;
        gap: 1.5rem;
    }

    .footer__content h1 {
        margin-left: 0;
        padding: 0.6rem;
        font-size: 1.5rem;
        line-height: 1.25rem; /* 140% */
    }

    .footer__content p {
        margin-left: 0;
        padding: 0.6rem;
        text-align: center;
        font-size: 1.25rem;
        line-height: 1.0rem; /* 140% */
    }

    .footer__content hr {
        display: inline;
        width: 75%;
        opacity: 0.15;
        margin: 0.75rem;
    }

    .footer__content-logo {
        align-self: center;
        text-align: center;
    }

    .footer__content-logo img {
        width: 10rem;
        padding: 0.5rem;
        align-self: center;
    }
}