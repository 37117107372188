
.about__landing {
    margin-left: 4rem;
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

.about__landing p {
    text-align: left;
    max-width: 48rem;
}

.about__landing-slogan p {
    margin-bottom: 0;
    line-height: 1em;
}

.about__landing-slogan h1 {
    color: var(--panton-milk);
    text-align: left;
    font-size: 3.5rem;
    margin-top: 0;
}

.about__landing-buttons {
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
}

.about__landing-buttons a {
    padding: 0.5rem 1.5rem;
}

.about__content {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1.5rem 0 1.5rem 10%;
}


.about__content p {
    color: #000;

    margin: 1rem 0;

    /* H4 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.about__content-about-text {
    display: none;
}

.about__video {
    display: flex;
    background-color: var(--panton-light);
    width: auto; 
    height: auto; 
}

.about__video iframe {
    height: auto !important;
    aspect-ratio: 16/9;
    border: none;
}

@media screen and (max-width: 1050px) {
    .about__landing {
        margin-left: 0;
    }
}

@media screen and (max-width: 850px) {
    .about__landing-slogan h1 {
        font-size: 3.25rem;
    }

    .about__landing-buttons {
        gap: 1rem;
    }
}

@media screen and (max-width: 650px) {
    .about__landing h1 {
        font-size: 2.75rem;
        padding: 0;
    }

    .about__landing-buttons {
        flex-direction: column;
        gap: 0;
    }

    .about__landing-buttons a {
        max-width: 24rem;
    }

    .about__video {
        margin: 0 0.5rem;
    }
}