.work__content h1 {
    color: var(--panton-white);
    text-align: center;
}

.work__title {
        margin-top: 3rem;
}

.work__landing {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    padding-top: 4rem;
}


.work__landing-info {
	align-self: center;

    width: 50%;
    padding: 1.5rem;
}

.work__landing-info h2 {
    
    margin-bottom: 1rem;
    line-height: 2.1rem; /* 175% */
}

.work__landing-info p {
    
    color: var(--panton-white);
    margin-bottom: 1rem;

    /* PF4 */
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 175% */
	
}

.work__landing-info span {
	font-style: italic;
}

.work__form-accept {
    width: 75%;
}


.work__landing-form {
    width: 50%;
    display: flex;
    justify-content: center;
}

.work__landing-info-mobile {
        display: none;
}

.work__landing-info-mobile h2 {
	color: var(--panton-purple);

	/* PF4 */
	font-family: Inter;
	font-size: 1.3rem;
	font-style: normal;
	font-weight: 550;
	line-height: 1.5rem; /* 175% */  
	margin: 0.5rem 0;
}

.work__landing-info-mobile p {
	color: var(--panton-black);
	

	/* PF4 */
	font-family: Inter;
	font-size: 1.1rem;
	font-style: normal;
	font-weight: 350;
	line-height: 1.5rem; /* 175% */
	margin: 0.5rem 0;
}

.work__search-by-mail {
    max-width: 1000px;
    background: var(--panton-lighter);
    padding: 1rem;
    margin:auto;
}

@media screen and (max-width: 850px) {
    .work__form-accept {
        width: 100%;
    }
    
    .work__landing-info {
        padding: 0;
        margin-right:10%;
    }
}

@media screen and (max-width: 650px) {
    .work__landing {
        flex-direction: column;
        padding-top: 0;
    }

    .work__title {
        margin-top: 20%;
    }

    .work__landing-form {
        width: 100%;
        margin-left: 0;
    }

    .work__landing-info {
        display: none;
    }

    .work__landing-info-mobile {
		display: flex;
		flex-direction: column;
		background: linear-gradient(153deg, rgba(254, 251, 255, 0.88) 0%, rgba(248, 238, 255, 0.5) 100%);
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
		padding: 1rem 1rem;
        margin: 3rem 1rem;;
    }
}
