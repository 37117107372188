
.button {
    border-radius: 4px;
    padding: 0.5rem;
    margin: 1.5rem 0;

    text-align: center;
    /* H4 */
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    transition: all 250ms;
    cursor: pointer;
}


.button_hollow-black {
    border: 1px solid var(--panton-black);
    color: var(--panton-black);
}

.button_hollow-white {
    border: 1px solid var(--panton-white);
    color: var(--panton-white);
}

.button_filled-black {
    background: var(--panton-milk);
    color: var(--panton-black) !important;
    border: none;
    transition: all 250ms;
    cursor: pointer;
}


/*   ANIMATIONS    */

.button_hollow-white:hover,
.button_hollow-white:active {
    background: rgba(255, 255, 255, 0.20);
    transform: translateY(-2px);
}

.button_hollow-black:hover,
.button_hollow-black:active {
    background: rgba(0, 0, 0, 0.20);
    transform: translateY(-2px);
}

.button_filled-black:hover,
.button_filled-black:active {
    background: rgb(196, 196, 196);
    transform: translateY(-2px);
}


/*   MOBILE    */

@media screen and (max-width: 850px) {
    .button {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 650px) { 
    .button {
        margin: 1.25rem 0;
        font-size: 1.25rem;
    }
}