.home__landing {
    padding-top: 2rem;
    margin-left: 4rem;
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

.home__landing h1 {
    color: var(--panton-milk);
    text-align: left;
    font-size: 4.4rem;
    line-height: 115%;
}

.home__landing p {
    max-width: 36rem;
    text-align: left;
    margin: 0.5rem 0;
}

.home__landing-slogan {
    margin-bottom: 0;
    line-height: em;
}

.home__landing-buttons {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}


.home__landing-buttons a {
    padding: 0.5rem 1.5rem;
}


.main__component {
    margin-bottom: 5rem;
}


.main__video {
    display: flex;
    background-color: var(--panton-light);
    width: auto; 
    height: auto; 
}

.main__video iframe {
    height: auto !important;
    aspect-ratio: 16/9;
    border: none;
}

@media screen and (max-width: 1050px) {
    .home__landing {
        margin-left: 0;
    }
}

@media screen and (max-width: 850px) {
    .home__landing h1 {
        font-size: 3.9rem;
    }

    .home__landing-buttons {
        gap: 1rem;
    }
}

@media screen and (max-width: 650px) {
    .home__landing h1 {
        font-size: 2.9rem;
        padding: 0;
    }

    .home__landing-buttons {
        flex-direction: column;
        gap: 0;
    }

    .home__landing-buttons a {
        max-width: 24rem;
    }

    .main__component {
        margin-bottom: 2rem;
    }
}