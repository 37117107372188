.policy__content {
    height: 100%;
    margin-top: 7rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem;
}

.policy__content h1 {
    font-size: 1.8rem;
    text-align: left;
}

.policy__content p {
    font-size: 1.1rem;
    text-align: left;
    font-weight: 400;
    margin: 0.5rem 0;
}

.policy__content li {
    color: var(--panton-white);
    font-family: Inter;
    font-size: 1.1rem;
    list-style-type: none;
    text-align: left;
    font-weight: 400;
    margin: 0.5rem 0;
}

.policy__content button {
    margin-top: 2.5rem;
    align-self: center;
    padding: 0.75rem 1.5rem;
}

@media screen and (max-width: 850px) {
    .policy__content h1 {
        font-size: 1.6rem;
    }
    
    .policy__content p {
        font-size: 1.0rem;
    }
}