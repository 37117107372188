.news__view {
    background: linear-gradient(153deg, rgba(254, 251, 255, 1) 0%, rgba(248, 238, 255, 1) 100%);
    padding: 0.25rem;
    z-index: -10;
}

.news__view h1 {

    text-align: center;

    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.news__container {
    display: flex;
    height: 38rem;
    margin-bottom: 1rem;
}

#news__change-alive {
    flex-shrink: 0;
    opacity: 0.8;
    cursor: pointer;

}

#news__change-dead {
    flex-shrink: 0;
    opacity: 0.25;
}


.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}
  
.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.news__see-more  {
    height: 100%;
    padding-top:50%;
    text-align: center;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
}
  
@media screen and (max-width: 650px) {
    .news__container {
        display: flex;
        width: 96%;
        height: 30rem;
        margin-left: 2%;
        margin-bottom: 1rem;
    }
  }