.home-highlight {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.home-highlight__contents {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5rem; 
}

.home-highlight__contents_highlight {
    flex: 0 0 calc(33.333% - 2.5rem); /* Adjust based on your gap size */
    max-width: calc(33.333% - 2.5rem); /* Ensures items don't exceed this width */
}

.home-highlight__contents_highlight h1 {
    margin-bottom: 1.25rem;
}

.home-highlight__contents_highlight-image {
    background: gray;
    box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 16rem;
    margin-bottom: 1.25rem;
}

.home-highlight__contents_highlight-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1050px) {
    .home-highlight__contents {
        gap: 2rem; 
    }

    .home-highlight__contents_highlight {
        flex: 0 0 calc(50% - 2.0rem); /* Adjust based on your gap size */
        max-width: calc(50% - 2.0rem); /* Ensures items don't exceed this width */
    }
}

@media screen and (max-width: 650px) {
    .home-highlight__contents {
        flex-direction: column;
        padding: 1rem;
        gap: 4rem; 
    }

    .home-highlight__contents_highlight-image {
        height: 18rem;
    }

    .home-highlight__contents_highlight {
        flex: 1; /* Adjust based on your gap size */
        max-width: 100%; /* Ensures items don't exceed this width */
    }
}
