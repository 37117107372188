.fade-in{animation:fade-in 0.85s cubic-bezier(.39,.575,.565,1.000) both}
.fade-out{animation:fade-out 0.75s ease-out both}

.header__bar {
    position: fixed;
    width: 100%;
    z-index: 100;
}

.header__content {
    width: 80%;
    margin-left: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.header__content h1 {
    margin: 0;
    padding: 0;
}

.header__content p {
    margin: 0;
    padding: 0;
}

.header__content-menu {
    display: flex;
    align-self: stretch;
    margin-left: auto;
    
}

.header__content-menu a {
    color: var(--panton-white);
    align-content: center;
    padding: 0 1rem;
    
    /* H4 */
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    white-space: nowrap;
}

.header__content-menu a:hover {
    background: rgba(255, 255, 255, 0.20);
}

.link__selected {
    background-color: rgba(255, 255, 255, 0.33);
}

.header__content-logo {
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-right: 0.5rem;

    text-decoration: none;
}

.header__content-logo:hover {
    background: rgba(255, 255, 255, 0.2);
}

.header__content-logo:active {
    background: rgba(255, 255, 255, 0.30);
}

.header__content-logo img {
    width: 100%;
}

#header__content-logo-bogfelts {
    color: var(--panton-purple);
    

    /* Log */
    -webkit-text-stroke: 1.3px #fff;
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-size: 3.6rem;
    font-style:normal;
    font-weight: 850;
    letter-spacing: -1px;
    line-height: 2.70rem;
    font-variation-settings: "wdth" 100;
    padding-top: 0.15rem;
}

#header__content-logo-sl {
    color: var(--panton-steet-lights);

    /* H4 */
    -webkit-text-stroke:1.5px var(--panton-white);
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-size: 2.0rem;
    letter-spacing: -1px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.70rem;
    text-align: center;
    font-variation-settings: "wdth" 100;
    margin-top:0.1rem;

    padding-bottom: 0.50rem;
}


.header__sidebar-button {
    display: none;
    align-self: stretch;
    align-content: center;
    padding: 0 0.5rem;
    padding-top: 0.25rem;
}

.header__sidebar-button:hover {
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

@media screen and (min-width: 2000px) {
    .header__content {
        width: 60%;
        margin-left: 20%;
    }
}

@media screen and (max-width: 1050px) {
    .header__content-menu a {
        font-size: 1.3rem;
    }

    .header__content-logo {
        padding-top: 0.25rem;
        padding-bottom: 0.1rem;
    }

    #header__content-logo-bogfelts {
        -webkit-text-stroke:1px var(--panton-white);
        font-size: 2.6rem;
        line-height: 2.2rem;
    }

    #header__content-logo-sl {
        -webkit-text-stroke:1px var(--panton-white);
        font-size: 1.5rem;
        line-height: 1.0rem;
    }

    .header__content-lang p {
        font-size: 1rem;
    }

    .header__language-bar {
        margin-left: 70%;
    }
}

@media screen and (max-width: 850px) {
    .header__content {
        width: 100%;
        margin-left: 0;
    }

    .header__content-menu a {
        padding: 0 0.35rem;
    }

    #header__content-logo-bogfelts {
        font-size: 2.4rem;
        line-height: 2.1rem;
    }

    #header__content-logo-sl {
        font-size: 1.40rem;
        line-height: 0.9rem;
    }
}

@media screen and (max-width: 650px) {
    .header__content-menu {
        display: none;
    }

    
    .header__content-logo {
        padding-top: 0.25rem;
        padding-bottom: 0.1rem;
    }
    
    #header__content-logo-bogfelts {
        -webkit-text-stroke: 1px var(--panton-white);
        font-size: 2.1rem;
        line-height: 1.55rem;
    }
    
    #header__content-logo-sl {
        font-size: 1.2rem;
        line-height: 0.9rem;
    }

    .header__content-lang p {
        font-size: 1rem;
    }

    .header__language-bar {
        margin-left: 60%;
    }

    .header__sidebar-button {
        display: inline;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-11 7:59:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in and out
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.666);
    }
}
@keyframes fade-in {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.666);
    }
}

@keyframes fade-out{0%{background: rgba(0, 0, 0, 0.666);}100%{background: rgba(0, 0, 0, 0)}}
  
/**
 * ----------------------------------------
 * animation swing in and out
 * ----------------------------------------
 */

.swing-in-top-fwd{-webkit-animation:swing-in-top-fwd .5s cubic-bezier(.175,.885,.32,1.275) both;animation:swing-in-top-fwd .5s cubic-bezier(.175,.885,.32,1.275) both}

.swing-out-top-bck{-webkit-animation:swing-out-top-bck .45s cubic-bezier(.4,-.28,.535,.045) both;animation:swing-out-top-bck .3s cubic-bezier(.6,-.28,.735,.045) both}

@-webkit-keyframes swing-in-top-fwd{0%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}100%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}}@keyframes swing-in-top-fwd{0%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}100%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}}

@-webkit-keyframes swing-out-top-bck{0%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}100%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}}@keyframes swing-out-top-bck{0%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}100%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}}