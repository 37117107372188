.work__availablework {
        
}

.work__availablework h1 {
    margin-top: 1.5rem;
    color: var(--panton-black);
}

.work__availablework-content {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    justify-content: center;

    gap: 2rem; /* Add some spacing between items */
}

.work__availablework-entry {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    width: 36rem;

    border-radius: 0.25rem;
    background: linear-gradient(153deg, rgba(254, 251, 255, 0.88) 0%, rgba(248, 238, 255, 0.5) 100%);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
}

.work-availablework-entry-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.work-availablework-entry-col {
    flex: 1;
}

.work__availablework-entry-title {
    
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    justify-content:center;
    margin-bottom: 0.5rem;
    
}

.work__availablework-entry-icon {
    padding: 0.15rem 0.25rem;
}

.work__availablework-entry-title h1 {
    color: var(--panton-blue);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.10);
}

.work__availablework-entry-label {
    color: var(--panton-black);

    margin-left: 0.25rem;
    margin-top: 0.25rem;

    /* PF4 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 175% */
}

.work__availablework-entry-field {
    background: var(--panton-white);
    
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    padding: 0.10rem 0.40rem;

    color: var(--panton-black);

    /* PF4 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 175% */
}

.work__availablework__submit {
    margin-bottom: 0.5rem;
}

.work__availablework-entry-field:hover {
    border: 1px solid rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 650px) {
    .work-availablework-entry-content {
        flex-direction: column;
    }

    .work__availablework-content {
        margin: 2rem 0.75rem;
        display: flex;
        flex-wrap: wrap; /* Allow items to wrap for better responsiveness */
        gap: 2.5rem; /* Add some spacing between items */
    
        justify-content: space-around;
    }
    
    .work__availablework-entry {
        width: 94%;
        padding: 0.5rem;
    }
}



/* imported style for button */

/* CSS */
.button-7 {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.0;
    margin: 0;
    min-height: 1.5rem;
    padding: calc(.6rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    margin-top: 1rem;

    margin-left: 25%;
    width: 50%;
  }
  
  .button-7:hover,
  .button-7:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
  }
  
  .button-7:hover {
    transform: translateY(-1px);
  }
  
  .button-7:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }