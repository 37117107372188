:root {
    --panton-purple: #583186;
    --panton-purple-text: #dbdbfc;
    --panton-medium: #713FAB;
    --panton-wine: #833186;
    --panton-green: #318658;
    --panton-olive: #5F8631;
    --panton-steet-lights: #3EA429;
    --panton-blue: #313486;
    --panton-light: #E1CAFE;
    --panton-lighter: #eae1f3;
    --panton-palette: #CB83E1;
    --panton-white: #FFF;
    --panton-black: #000;
    --panton-gray: #B9B9B9;
    --panton-gray-light: #D8D8D8;
    --panton-milk: #FAFAFA;

    --panton-purple-ghost: #583186B0;
    --panton-medium-ghost: #713FABB0;
    --panton-green-ghost: #318658B0;
    --panton-steet-lights-ghost: #3EA429B0;

    --panton-purple-fade-right: linear-gradient(90deg, rgba(88, 49, 134, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }