.company-information {
    display: flex;
    flex-direction:row;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.company-information.company__right  {
    flex-direction:row-reverse;
}

.company-information h1 {
    color: var(--panton-black);

    /* H4 */
    font-family: Inter;
    text-align: left;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.company-information p {
    color: var(--panton-black);

    margin: 1rem 0 !important;

    /* H4 */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 1.4;
}

.company-information hr {
    background: var(--panton-medium);
    width: 98%;
    height: 1px;
    border: 1px solid var(--panton-palette);
    border-radius: 2px;
    opacity: 0.25;
    margin: 1rem 0 1rem 1%;
}

.company-information-image {
    flex: 1;
}

.company-information img {
    border-radius: 0.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.20);
    max-width: 100%;
    height: 100%;
    object-fit:cover;
}

.company-information-text {
    flex: 1;
    padding-left: 2rem;
    align-content: flex-start;
}

.company-information-text.company__right {
    padding-left: 0;
    padding-right: 2rem;
}

.hidden {
    opacity: 0;
}

.informationSwipe {
    padding-bottom: 4rem;
    margin: auto;
    width: 36vw;
    height: 100%;
    
    top: 0;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--panton-white) !important;
  margin: 0  0.5rem;
  top: calc(50% - var(--swiper-navigation-size) / 2) !important;  
  z-index: 2;
  transition: color 200ms;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: var(--panton-lighter) !important;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-24 7:37:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-right-fwd
 * ----------------------------------------
 */

.swing-in-right-fwd {
	-webkit-animation: swing-in-right-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-right-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.swing-in-left-fwd {
	-webkit-animation: swing-in-left-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-left-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

 @-webkit-keyframes swing-in-right-fwd {
    0% {
      -webkit-transform: rotateY(-100deg);
              transform: rotateY(-100deg);
      -webkit-transform-origin: right;
              transform-origin: right;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: right;
              transform-origin: right;
      opacity: 1;
    }
  }
  @keyframes swing-in-right-fwd {
    0% {
      -webkit-transform: rotateY(-100deg);
              transform: rotateY(-100deg);
      -webkit-transform-origin: right;
              transform-origin: right;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: right;
              transform-origin: right;
      opacity: 1;
    }
  }
  
@-webkit-keyframes swing-in-left-fwd {
    0% {
      -webkit-transform: rotateY(100deg);
              transform: rotateY(100deg);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 1;
    }
  }
  @keyframes swing-in-left-fwd {
    0% {
      -webkit-transform: rotateY(100deg);
              transform: rotateY(100deg);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 1;
    }
  }


@-webkit-keyframes swing-in-bottom-fwd {
        0% {
          -webkit-transform: rotateX(100deg);
                  transform: rotateX(100deg);
          -webkit-transform-origin: bottom;
                  transform-origin: bottom;
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateX(0);
                  transform: rotateX(0);
          -webkit-transform-origin: bottom;
                  transform-origin: bottom;
          opacity: 1;
        }
      }
      @keyframes swing-in-bottom-fwd {
        0% {
          -webkit-transform: rotateX(100deg);
                  transform: rotateX(100deg);
          -webkit-transform-origin: bottom;
                  transform-origin: bottom;
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateX(0);
                  transform: rotateX(0);
          -webkit-transform-origin: bottom;
                  transform-origin: bottom;
          opacity: 1;
        }
      }
      
  
  /**
 * ----------------------------------------
 * mobile versions
 * ----------------------------------------
 */

 @media screen and (max-width: 1050px) {
        .informationSwipe {
                width: 40vw;
                padding-bottom: 3rem;
        }
 }

 @media screen and (max-width: 850px) {
        .company-information {
            flex-direction:column-reverse;
            padding: 0;
            margin: 2rem 0.5rem;
        }
    
        .company-information.company__right {
            flex-direction:column-reverse;
        }

        .company-information-image {
            padding: 0 0.5rem;
        }
    
        .company-information img {
            border-radius: 0.5rem;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            width: 100%;
        }
    
        .company-information h1 {
            font-size: 1.75rem;
            text-align: start !important;
            margin-top: 0.5rem;
            text-align: center;
        }
    
        .company-information-text {
            padding: 0.5rem;
        }

        .company-information-text.company__right {
            padding: 0.5rem;
        }

        .informationSwipe {
            width: 90vw;
            height: 100%;
            padding-bottom: 3.5rem;
        }

        .swiper-button-prev,
        .swiper-button-next {
        margin: 0  0.15rem;
        }

        .swing-in-right-fwd {
                -webkit-animation: swing-in-bottom-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	                animation: swing-in-bottom-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
        }
        
        .swing-in-left-fwd {
                -webkit-animation: swing-in-bottom-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	                animation: swing-in-bottom-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
        }
    
    }